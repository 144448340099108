
import { defineComponent, ref, PropType, watch } from 'vue'
import Cloudfun from '@cloudfun/core'

interface PostalCode {
  City: string;
  Code: string;
  District: string;
}

interface Address {
  City: string;
  PostalCode: string;
  District: string;
  DistrictDisplay?: string;
  Line: string;
}

export default defineComponent({
  name: "AddressSelectBox",
  props: {
    modelValue: Object as PropType<Address>
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const postalCodes: PostalCode[] = (Cloudfun.current?.enums as any).PostalCodes;
    const cities = new Set([...postalCodes.map(e => e.City)]);
    const districts = ref<string[]>([]);
    const currentValue = ref<Address>({ City: "", PostalCode: "", District: "", DistrictDisplay: "", Line: "" });

    const changeCity = (value: string) => {
      districts.value = postalCodes.filter(e => e.City === value).map(e => e.Code + " " + e.District);
      currentValue.value.DistrictDisplay = "";
    }

    watch(() => props.modelValue, (val, oldVal) => {
      if (!val) return;
      currentValue.value.City = val.City || "";
      changeCity(currentValue.value.City);
      currentValue.value.DistrictDisplay = val.PostalCode && val.District ? val.PostalCode + " " + val.District : "";
      currentValue.value.Line = val.Line || "";
    }, { immediate: true });

    const updateValue = () => {
      if (!currentValue.value.City || !currentValue.value.DistrictDisplay || !currentValue.value.Line) return;
      const codes = currentValue.value.DistrictDisplay.split(' ');
      const model: Address = {
        City: currentValue.value.City,
        District: codes[1],
        PostalCode: codes[0],
        Line: currentValue.value.Line
      }
      emit("update:modelValue", model);
    }
    return {
      currentValue,
      changeCity,
      cities,
      districts,
      updateValue
    }
  }
})
