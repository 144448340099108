
import { defineComponent, ref, reactive, PropType, watch } from "vue";
import {
  toStringDate as toDate,
  toDateString as toString,
} from "xe-utils/date";

type QueryDate = {
  startDate?: string | Date;
  endDate?: string | Date;
};

export default defineComponent({
  props: {
    dateType: {
      type: String as PropType<"m" | "d" | "w" | "c">,
      default: "d"
    },
    returnAsStirng: {
      type: Boolean,
      default: false
    },
    triggerType: {
      type: String as PropType<"immediate" | "button">,
      default: "button"
    },
    startDate: [String, Date],
    endDate: [String, Date],
  },
  emits: ["query"],
  setup(props, { emit }) {
    const type = ref(props.dateType);
    const input = reactive({
      date: "",
      month: "",
      week: "",
      range: {
        start: props.startDate,
        end: props.endDate,
      },
    });

    const queryData = reactive<QueryDate>({
      startDate: "",
      endDate: "",
    });

    const getDate = () => {
      switch (type.value) {
        case "d":
          if (props.returnAsStirng) {
            queryData.startDate = input.date;
            queryData.endDate = input.date + ' 23:59:59'
          } else {
            queryData.startDate = toDate(input.date);
            queryData.endDate = new Date(queryData.startDate.getTime());
            queryData.endDate.setHours(23, 59, 59, 0);
          }
          break;
        case "m":
          if (props.returnAsStirng) {
            const date = toDate(input.month + "/01");
            queryData.startDate = toString(date, "yyyy/MM/dd")
            queryData.endDate = toString(date, "yyyy/MM/dd 23:59:59")
          } else {
            queryData.startDate = toDate(input.month + "/01");
            queryData.endDate = new Date(queryData.startDate.getTime());
            queryData.endDate.setMonth(queryData.endDate.getMonth() + 1);
            queryData.endDate.setDate(-1);
            queryData.endDate.setHours(23, 59, 59, 0);
          }
          break;
        case "w":
          if (props.returnAsStirng) {
            queryData.startDate = input.week;
            const date = toDate(input.week);
            date.setDate(date.getDate() + 6);
            queryData.endDate = toString(date, "yyyy/MM/dd 23:59:59")
          } else {
            queryData.startDate = toDate(input.week);
            queryData.endDate = toDate(input.week);
            queryData.endDate.setDate(queryData.endDate.getDate() + 6);
            queryData.endDate.setHours(23, 59, 59, 0);
          }
          break;
        case "c":
          if (props.returnAsStirng) {
            queryData.startDate = input.range.start;
            queryData.endDate = input.range.end + ' 23:59:59'
          } else {
            queryData.startDate = input.range.start && toDate(input.range.start);
            queryData.endDate = input.range.end && toDate(input.range.end);
            (queryData.endDate as Date).setHours(23, 59, 59, 0);
          }
          break;
      }
    };

    const query = () => {
      getDate();
      emit("query", queryData);
    };

    if (props.triggerType === "immediate") {
      watch(input, () => query());
    }

    return {
      type,
      input,
      query
    }
  }
})

