
import { defineComponent, ref, reactive } from "vue";
import CloudFun, { Condition, Operator } from "@cloudfun/core";
import axios from "axios";
import { commafy } from "xe-utils";
import { helper as $h } from "@/utils/helper";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps, VxeTableProps } from "vxe-table";
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import AddressSelectBox from "@/components/address-select-box/Main.vue";
import DateRange from "@/components/date-select/Range.vue";

interface QueryParams {
  source: number | null;
  status: number | null;
  startDate: any; // Date | null;
  endDate: any; // Date | null;
  productId: number;
  Id: number;
}

function queryToCondition(condition: Condition, query: QueryParams): Condition {
  condition.and("DataMode", Operator.NotEqual, 2);
  if (query.startDate) {
    condition.and(
      "Time",
      Operator.GreaterThanOrEqual,
      query.startDate
    );
  }
  if (query.endDate) {
    condition.and("Time", Operator.LessThan, query.endDate);
  }
  if (query.source != null) {
    condition.and("OrderSource", Operator.Equal, query.source);
  }
  if (query.status != null) {
    condition.and("Status", Operator.Equal, query.status);
  }
  if (query.productId) {
    condition.and("Items.ProductId", Operator.Equal, query.productId);
  }
  if (query.Id) {
    condition.and("Id", Operator.Equal, query.Id);
  }
  return condition;
}

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    AddressSelectBox,
    DateRange
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const toggleVisible = reactive({
      items: true,
      sender: true,
      receiver: true,
      invoice: true,
      payments: true
    })
    const isExporting = ref(false);
    const gridQueryParams = reactive<QueryParams>({
      source: null,
      status: null,
      startDate: new Date(),
      endDate: new Date(),
      productId: 0,
      Id: 0
    });
    gridQueryParams.endDate.setHours(23, 59, 59, 0)
    gridQueryParams.startDate!.setMonth(gridQueryParams.startDate!.getMonth() - 1);

    const gridOptions: GridOptions = {
      title: "訂單",
      canCreate: true,
      canUpdate: true,
      canDelete: false,
      stripe: false,
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      columns: [
        { field: 'Time', width: "150", title: '時間', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => $h.formatDate(cellValue, 'YYYY/MM/DD HH:mm:ss') },
        { field: "Number", title: "編號", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Member.Person.Name", title: "會員姓名", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Member.Person.MobilePhone", title: "會員電話", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Status", width: "75", title: "狀態", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.OrderStatus).find(e => e.Value === cellValue)?.Name : undefined },
        { field: "Type", width: "80", title: "付款方式", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.PaymentType).find(e => e.Value === cellValue)?.Name : undefined },
        { field: "OrderSource", width: "75", title: "類型", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.OrderSource).find(e => e.Value === cellValue)?.Name : undefined },
        { field: "SaleAmount", title: "售價總額", showHeaderOverflow: true, showOverflow: true, align: "right", formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue) },
        { field: "DiscountAmount", title: "折扣總額", showHeaderOverflow: true, showOverflow: true, align: "right", formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue) },
        { field: "PointsAmount", title: "紅利點數", showHeaderOverflow: true, showOverflow: true, align: "right", sortable: true },
        { field: "Amount", title: "訂單金額", showHeaderOverflow: true, showOverflow: true, align: "right", formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue) },
        { field: "Cash", title: "現金金額", showHeaderOverflow: true, showOverflow: true, align: "right", formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue) },
        { field: "CreditCard", title: "刷卡金額", showHeaderOverflow: true, showOverflow: true, align: "right", formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue) },
        { field: "ATM", title: "匯款金額", showHeaderOverflow: true, showOverflow: true, align: "right", formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue) },
      ],
      rowClassName(params) {
        const enums = model?.enums as any;
        return params.row.Status === enums.OrderStatus.PaymentConfirmed.Value
          ? "bg-indigo-100"
          : params.row.Status === enums.OrderStatus.Finished.Value
            ? "bg-gray-300"
            : ""
      },
      promises: {
        query: model ? (params) => {
          if (!params.sortings) params.sortings = [];
          params.sortings.push({ column: "Time", order: 1 });
          params.condition = queryToCondition(params.condition!, gridQueryParams);
          params.condition.and("Payment", Operator.Equal, "");
          return model.dispatch("order/query", params);
        } : undefined,
        queryAll: model ? () => model.dispatch("order/query") : undefined,
        save: model ? (params) => model!.dispatch("order/save", params) : undefined,
      },
      modalConfig: { width: "100%", height: "100%", showFooter: true },
    };

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: "right",
      span: 3,
      items: [
        { field: "Time", title: "下單時間", span: 12, itemRender: { name: "$input", props: { type: "datetime", disabled: true } } },
        { field: "Number", title: "編號", span: 12, itemRender: { name: "$input", props: { placeholder: "未輸入則系統自動產生" } } },
        { field: "MemberId", title: "會員", span: 12, slots: { default: "column-member-id" } },
        // { field: "Status", title: "狀態", span: 12, itemRender: { name: "$select", options: model ? Object.values(model.enums.OrderStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { title: "狀態", span: 12, slots: { default: "column-status" } },
        { field: "ShippingAmount", title: "運費金額", span: 12, itemRender: { name: "$input", props: { type: "number" } } },
        { field: "AdjustAmount", title: "現金折抵", span: 12, itemRender: { name: "$input", props: { type: "number" } } },
        { field: "OrderSource", title: "類型", span: 12, itemRender: { name: "$select", options: model ? Object.values(model.enums.OrderSource).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        // { field: "SaleAmount", title: "原價總額", span: 12, itemRender: { name: "$input", props: { type: "number" } } },
        // { field: "DiscountAmount", title: "折扣總額", span: 12, itemRender: { name: "$input", props: { type: "number" } } },
        { field: "Amount", title: "訂單金額", span: 12, itemRender: { name: "$input", props: { type: "number" } } },
        { span: 24, slots: { default: "column-items" } },
        { title: "備註", span: 24, slots: { default: "column-remark" } },
        { span: 24, slots: { default: "column-shipping" } },
        // { span: 24, slots: { default: "column-payments" } }
        // { title: "發票資訊", span: 24, titleWidth: "100", titleAlign: "left" },
        // { field: "InvoiceAddress", span: 24, slots: { default: "column-invoice" } }
      ],
      rules: {
        // MemberId: [{ required: true }],
        // SaleAmount: [{ required: true }],
        // Amount: [{ required: true }],
      }
    };

    const memberSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: 'value',
      placeholder: '選擇會員',
      textField: 'label',
      valueField: 'value',
      columns: [
        { field: 'label', title: '姓名', showHeaderOverflow: true, showOverflow: true, sortable: true }
      ],
      promises: {
        find: (value) => new Promise(resolve => {
          model!.dispatch("member/find", value).then(res => resolve({ label: res.Person.Name, value: res.Id }));
        }),
        query: (params) => model!.dispatch("member/options", params) // eslint-disable-line
      },
    }

    const productSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: 'Id',
      placeholder: '選擇商品',
      textField: 'Name',
      valueField: 'Id',
      transfer: true,
      columns: [
        { field: 'Brand.Name', title: '品牌', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Number', title: '編號', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      formatText(row) {
        return Promise.resolve(row ? `(${row.Brand?.Name}) ${row.Number} ${row.Name}` : "");
      },
      promises: {
        find: (value) => model!.dispatch("product/find", value),
        query: (params) => model!.dispatch("product/query", params) // eslint-disable-line
      },
    }

    const itemsGrid = ref<any>({});
    const itemsGridOptions = {
      config: {
        autoResize: true,
        keepSource: true,
        showFooter: true,
        editConfig: { trigger: 'manual', mode: 'row', autoClear: false },
        editRules: {
          ProductId: [{
            // required: true,
            message: '必要欄位',
            validator: (params) => {
              if (params.row.SalePrice > 0 && !params.row.ProductId) {
                return new Error('必要欄位');
              }
            },
          }],
          Brand: [
            {
              required: true,
              validator: (params) => {
                if (typeof params.row.BrandName !== 'string') {
                  return new Error('格式錯誤');
                }
              },
            }
          ],
          Number: [
            {
              required: true,
              validator: (params) => {
                if (typeof params.row.ProductNumber !== 'string') {
                  return new Error('格式錯誤');
                }
              },
            }
          ],
          Barcode: [
            {
              required: true,
              validator: (params) => {
                if (typeof params.row.ProductBarcode !== 'string') {
                  return new Error('格式錯誤');
                }
              },
            }
          ],
          SalePrice: [
            { required: true, type: 'number' }
          ],
          // Discount: [
          //   { type: 'number', min: 0 }
          // ],
          Price: [
            { required: true, type: 'number' } // , min: 1, message: '金額須為1以上'
          ],
          Quantity: [
            { required: true, type: 'number', min: 1, message: '數量須為1以上' }
          ],
          Pices: [
            {
              required: false,
              validator: (params) => {
                if (typeof params.row.Pices !== 'string') {
                  return new Error('格式錯誤');
                }
              },
            }
          ],
        },
        footerMethod({ columns, data }) {
          return [
            columns.map((column, columnIndex) => {
              if (columnIndex === 3) {
                return "原價總額";
              }
              if (columnIndex === 4) {
                return commafy(data.reduce((pre, cur) => pre + (cur.Price * cur.Quantity), 0));
              }
              if (columnIndex === 5) {
                return "折扣總額\n紅利點數";
              }
              if (columnIndex === 6) {
                return -discountAmount.value + "\n" + -pointsAmount.value
              }
              if (columnIndex === 7) {
                return "訂單金額";
              }
              if (columnIndex === 8) {
                return commafy((orderAmount.value ?? 0));
                // return "123456";
              }
            })
          ]
        }
      } as VxeTableProps,
      async insert() {
        const { row: newRow } = await itemsGrid.value.insert({
          SalePrice: 1,
          Price: 1,
          Discount: 0,
          Quantity: 1
        });
        itemsGrid.value.setActiveRow(newRow);
      },
      edit(row: any) {
        itemsGrid.value.setActiveRow(row);
      },
      delete(row: any) {
        itemsGrid.value.remove(row);
      },
      async cancel(row: any) {
        if (row.Id) {
          itemsGrid.value.revertData(row)
          itemsGrid.value.clearActived();
          return;
        }

        if (!row.ProductId || !row.SalePrice || !row.Price || !row.Quantity) {
          itemsGridOptions.delete(row);
          return;
        }

        itemsGrid.value.clearActived();
      },
      async save(row: any) {
        const error = await itemsGrid.value.validate(row);
        if (!error) {
          itemsGrid.value.clearActived();
        }
      }
    }

    const paymentsGrid = ref<any>({});
    const paymentsGridOptions = {
      config: {
        autoResize: true,
        keepSource: true,
        showFooter: true,
        editConfig: { trigger: 'manual', mode: 'row', autoClear: false },
        editRules: {
          Time: [{ required: true, message: '必要欄位' }],
          Type: [{ required: true, message: '必要欄位' }],
          Pan: [{ required: true, message: '必要欄位' }],
          Amount: [{ required: true, message: '必要欄位' }],
          Status: [{ required: true, message: '必要欄位' }]
        }
      } as VxeTableProps,
      async insert() {
        const { row: newRow } = await paymentsGrid.value.insert({
          Time: new Date(),
          Type: 1,
          Amount: 1,
          Status: 10
        });
        paymentsGrid.value.setActiveRow(newRow);
      },
      edit(row: any) {
        paymentsGrid.value.setActiveRow(row);
      },
      delete(row: any) {
        paymentsGrid.value.remove(row);
      },
      async cancel(row: any) {
        if (row.Id) {
          paymentsGrid.value.revertData(row)
          paymentsGrid.value.clearActived();
          return;
        }

        if (!row.Time || !row.Type || !row.Pan || !row.Amount || !row.Status) {
          paymentsGridOptions.delete(row);
          return;
        }

        paymentsGrid.value.clearActived();
      },
      async save(row: any) {
        const error = await paymentsGrid.value.validate(row);
        if (!error) {
          paymentsGrid.value.clearActived();
        }
      }
    }

    // 0718 補上調整金額及運費金額
    const adjustAmount = ref<number>(0);
    const shippingAmount = ref<number>(0);
    const orderAmount = ref<number>(0);
    const pointsAmount = ref<number>(0);
    const discountAmount = ref<number>(0);

    return {
      isExporting,
      grid,
      gridQueryParams,
      gridOptions,
      formOptions,
      memberSelectOptions,
      productSelectOptions,
      toggleVisible,
      itemsGrid,
      itemsGridOptions,
      paymentsGrid,
      paymentsGridOptions,
      adjustAmount,
      shippingAmount,
      orderAmount,
      pointsAmount,
      discountAmount,
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    async onGridEdit(row: any, callback: any) {
      if (!row.Id) {
        row.Status = 0;
        row.OrderSource = 0;
        row.SaleAmount = 0;
        row.AdjustAmount = 0;
        row.ShippingAmount = 0;
        row.DiscountAmount = 0;
        row.Amount = 0;
        row.Shipping = {
          ReceiverAddress: {}
        };
      } else {
        const entity = await this.$model.dispatch("order/find", row.Id);
        Object.assign(row, entity);
        this.adjustAmount = row.AdjustAmount;
        this.shippingAmount = row.ShippingAmount;
        this.orderAmount = row.Amount;
        this.pointsAmount = row.PointsAmount;
        this.discountAmount = row.DiscountAmount;
        if (row.Items?.length > 0) {
          const itemIds = row.Items.filter((e: any) => e.ProductId).map((e: any) => e.ProductId);
          const items = await this.$model.dispatch("product/query", { condition: new Condition("Id", Operator.In, itemIds) });
          row.Items.filter((e: any) => e.ProductId).forEach((e: any) => {
            const item = items.find((i: any) => i.Id === e.ProductId);
            e.ProductName = `(${item.Brand.Name}) ${item.Number} ${item.Name}`;
            e.BrandName = `${item.Brand.Name}`
            e.ProductNumber = `${item.Number}`
            e.ProductBarcode = `${item.Barcode}`
            e.ProductPhotoUri = `${item.Photo.Uri}`
          });
        }
        row.Payments = await this.$model.dispatch("payment/query", { condition: new Condition("OrderId", Operator.Equal, row.Id) });

        row.Shipping.ReceiverAddress = row.Shipping.ReceiverAddress || {};
        row.Shipping.InvoiceAddress = row.Shipping.InvoiceAddress || {};
        // 只保留數字
        row.Shipping.ReceiverMobilePhone = row.Shipping.ReceiverMobilePhone === null ? null : row.Shipping.ReceiverMobilePhone.replace(/\D/g, '');
        row.Shipping.SenderMobilePhone = row.Shipping.SenderMobilePhone === null ? null : row.Shipping.SenderMobilePhone.replace(/\D/g, '');
      }
      callback();
    },
    async onGridRemove(row: any) {
      const comfirmedCardPayment = (await this.$model.dispatch("payment/query", { page: 1, pageSize: 1, condition: new Condition("DataMode", Operator.NotEqual, 2).and("OrderId", Operator.Equal, row.Id).and("Type", Operator.Equal, 1).and("Status", Operator.Equal, 24) }))?.data[0];
      const msg = comfirmedCardPayment ? "此訂單已刷卡付款，請記得去綠界進行退刷，確定要取消此筆資料嗎?" : "確定要取消此筆資料嗎?";
      if (this.gridOptions.promises?.save && confirm(msg)) {
        this.grid.gridOptions.loading = true;
        row.Status = 30;
        this.gridOptions.promises.save({ updateRows: [row] }).then(
          () => {
            this.grid.instance.commitProxy("query").finally(() => {
              this.grid.gridOptions.loading = false;
            });
          },
          (failure: any) => {
            if (Array.isArray(failure)) {
              failure.forEach((e: any) =>
                CloudFun.send("error", {
                  subject: "取消失敗",
                  content: e.message,
                })
              );
            } else {
              CloudFun.send("error", {
                subject: "取消失敗",
                content: failure.message || failure,
              });
            }
            this.grid.gridOptions.loading = false;
          }
        );
      }
    },
    onFormSubmit(row: any, callback: any) {
      row.Items = this.itemsGrid.getTableData().fullData;
      // row.Payments = this.paymentsGrid.getTableData().fullData;
      callback();
    },
    async onProductSelectChange(id: number, row: any) {
      const product = await this.$model.dispatch("product/find", id)
      row.ProductName = product.Name;
      row.Price = product.PurchasePrice;
      row.Discount = product.PurchaseDiscount || 0;
      row.SalePrice = product.SalePice;
    },
    async onExport(type: number, orderId: number) {
      this.hideBatchDropDown();
      let requestUrl = "";
      let filename = "";
      const params = { ...this.gridQueryParams };
      switch (type) {
        case 0:
          requestUrl = "/api/Reports/Order";
          filename = "訂單總表.xlsx";
          break;
        case 1:
          requestUrl = "/api/Reports/Order/Details";
          filename = "訂單明細表.xlsx";
          break;
        case 2:
          params.startDate = null;
          params.endDate = null;
          requestUrl = "/api/Reports/Order/Daily";
          filename = "訂單日報表.xlsx";
          break;
        case 3:
          params.Id = orderId;
          requestUrl = "/api/Reports/Order/Details";
          filename = "訂單資料.xlsx";
          break;
        default:
          return;
      }
      console.log(params);
      requestUrl = process.env.VUE_APP_BACKEND_URL + requestUrl;
      this.isExporting = true;
      // 使用此種方式會有Uncaught (in promise) undefined的問題
      // model!.dispatch("order/export", { orderId: grid.value.editingRow.Id })
      axios.post(requestUrl,
        { condition: queryToCondition(new Condition(), params), sortings: [{ column: "Time", order: 1 }] },
        { responseType: "blob", headers: { Authorization: 'Basic ' + btoa(`${this.$user?.Id}:${this.$user?.Token}`) } })
        .then((response: any) => {
          const a = document.createElement("a");
          const url = window.URL.createObjectURL(response.data);
          a.href = url;
          a.target = "_blank";
          a.download = filename;
          a.click();
        }).finally(() => {
          this.isExporting = false;
        });
    }
  }
});
