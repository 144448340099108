<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-2xl font-bold mr-auto">訂單管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center">
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" />
                匯出 CSV
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span> -->
            </div>
          </div>
        </div>
        <div id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <vxe-button class="dropdown-toggle px-2" content="匯出報表" :loading="isExporting">
          </vxe-button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <span
                class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="onExport(0, 0)">
                訂單總表
              </span>
              <span
                class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="onExport(1, 0)">
                訂單明細表
              </span>
              <span
                class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="onExport(2, 0)">
                日報表
              </span>
            </div>
          </div>
        </div>
        <button v-show="!grid.isEditing" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="sm:flex flex-wrap sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-3 mt-2 xl:mt-0 ">
            <DateRange triggerType="immediate" :start-date="gridQueryParams.startDate"
              :end-date="gridQueryParams.endDate" date-type="c"
              @query="e => { gridQueryParams.startDate = e.startDate; gridQueryParams.endDate = e.endDate; }" />
            <vxe-select v-model="gridQueryParams.source" clearable placeholder="請選擇來源" class="mr-2">
              <vxe-option v-for="item in Object.values($enums.OrderSource)" :key="'source-' + item.Value"
                :value="item.Value" :label="item.Name">
              </vxe-option>
            </vxe-select>
            <vxe-select v-model="gridQueryParams.status" clearable placeholder="請選擇狀態" class="mr-2">
              <vxe-option v-for="item in Object.values($enums.OrderStatus)" :key="'status-' + item.Value"
                :value="item.Value" :label="item.Name">
              </vxe-option>
            </vxe-select>
            <SelectBox class="flex-1" v-bind="productSelectOptions" v-model="gridQueryParams.productId" />
          </div>
          <div class="flex items-center mt-2 xl:mt-0">
            <input type="text" class="input w-full sm:w-40 mt-2 sm:mt-0 border mr-2 py-1.5" v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字" />
            <button type="button" class="button w-full sm:w-16 mt-2 sm:mt-0 bg-theme-1 text-white py-1.5"
              @click="grid.refresh">
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <!-- <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a> -->
          <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a> -->
          <!-- </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid ref="grid" v-bind="gridOptions" @edit="onGridEdit" @removeSelectedRows="hideBatchDropDown()">
          <template #operate="{ row }"  width="250">
            <div class="flex justify-center" v-if="!grid.isEditing">
              <button class="button rounded-full mr-1 bg-blue-600 text-white" title="編輯" type="button"
                @click.stop="grid.edit(row)">
                <FontAwesome icon="highlighter" class="w-4 h-4" />
              </button>
              <!-- <button class="button rounded-full mr-1 bg-blue-600 text-white" title="列印" type="button"
                @click.stop="onExport(3, row.Id)">
                <FontAwesome icon="highlighter" class="w-4 h-4" />
              </button> -->
              <button v-if="row.Status !== $enums.OrderStatus.Canceled.Value"
                class="button rounded-full bg-red-600 text-white" title="取消" @click.stop="onGridRemove(row)">
                <FontAwesome icon="trash" class="w-4 h-4" />
              </button>
            </div>
          </template>

          <template #modal="{ row, submit, reset }">
            <vxe-form ref="form" v-bind="formOptions" :data="row" @reset="reset" @submit="onFormSubmit(row, submit)">
              <template #column-member-id="{ data }">
                <SelectBox v-bind="memberSelectOptions" v-model="data.MemberId" />
              </template>

              <template #column-status="{ data }">
                <VxeSelect v-model="data.Status" v-if="data.Status !== $model.enums.OrderStatus.Canceled.Value">
                  <VxeOption
                    v-for="(item, key) in Object.values($model.enums.OrderStatus).filter(e => e.Value == $model.enums.OrderStatus.PaymentConfirming.Value
                      || e.Value == $model.enums.OrderStatus.PaymentConfirmed.Value
                      || e.Value == $model.enums.OrderStatus.Finished.Value
                      || e.Value == $model.enums.OrderStatus.Canceled.Value
                      || e.Value == $model.enums.OrderStatus.InProgress.Value
                      || e.Value == $model.enums.OrderStatus.Established.Value
                      || e.Value == $model.enums.OrderStatus.Refused.Value
                      || e.Value == $model.enums.OrderStatus.Refunded.Value
                    )"
                    :key="'status-' + key" :label="item.Name" :value="item.Value"></VxeOption>
                </VxeSelect>
                <VxeInput v-else :modelValue="$model.enums.OrderStatus.Canceled.Name"></VxeInput>
              </template>

              <template #column-items="{ data }">
                <div class="intro-y overflow-hidden box border p-3">
                  <div class="flex items-center ml-1 mb-3">
                    <a href="#" @click.prevent="toggleVisible.items = !toggleVisible.items">訂單明細</a>
                    <a href="#" @click.prevent="toggleVisible.items = !toggleVisible.items">
                      <FontAwesome class="ml-2 h-4" :icon="toggleVisible.items ? 'angle-up' : 'angle-down'" />
                    </a>
                  </div>
                  <div v-show="toggleVisible.items">
                    <VxeTable ref="itemsGrid" :data="data?.Items" v-bind="itemsGridOptions.config">
                      <VxeColumn field="ProductId" title="商品圖片" resizable :edit-render="{}" auto-resize width="100">
                        <template #default="{ row }">
                          <div class="w-full py-2 flex justify-center items-center">
                            <img class="w-16 rounded" :src="row.ProductPhotoUri" alt="">
                          </div>
                        </template>

                        <template #edit="{ row }">
                          <div class="w-full py-2 flex justify-center items-center">
                            <img class="w-16 rounded" :src="row.ProductPhotoUri" alt="">
                          </div>
                        </template>
                      </VxeColumn>
                      <VxeColumn field="ProductId" title="商品" resizable :edit-render="{}" auto-resize min-width="180">

                        <template #default="{ row }">
                          <span>{{ row.ProductName }}</span>
                        </template>

                        <template #edit="{ row }">
                          <SelectBox v-bind="productSelectOptions" v-model="row.ProductId"
                            @change="value => onProductSelectChange(value, row)" />
                        </template>
                      </VxeColumn>
                      <!-- <VxeColumn field="Brand" title="品牌" resizable :edit-render="{}">

                        <template #default="{ row }">
                          <span>{{ row.BrandName }}</span>
                        </template>

                        <template #edit="{ row }">
                          <vxe-input type="string" v-model="row.BrandName" />
                        </template>
                      </VxeColumn>
                      <VxeColumn field="Number" title="編號" resizable :edit-render="{}">

                        <template #default="{ row }">
                          <span>{{ row.ProductNumber }}</span>
                        </template>

                        <template #edit="{ row }">
                          <vxe-input type="string" v-model="row.ProductNumber" />
                        </template>
                      </VxeColumn> -->
                      <VxeColumn field="Barcode" title="條碼" resizable :edit-render="{}">
                        <template #default="{ row }">
                          <span>{{ row.ProductBarcode }}</span>
                        </template>
                        <template #edit="{ row }">
                          <vxe-input type="string" v-model="row.ProductBarcode" />
                        </template>
                      </VxeColumn>
                      <VxeColumn field="Price" title="原價" resizable :edit-render="{}">
                        <template #edit="{ row }">
                          <vxe-input type="number" v-model="row.Price" />
                        </template>
                      </VxeColumn>
                      <VxeColumn field="SalePrice" title="售價" resizable :edit-render="{}">
                        <template #edit="{ row }">
                          <vxe-input type="number" v-model="row.SalePrice" />
                        </template>
                      </VxeColumn>
                      <!-- <VxeColumn field="Pices" title="片數" resizable :edit-render="{}">
                        <template #edit="{ row }">
                          <vxe-input type="string" v-model="row.SalePrice" />
                        </template>
                      </VxeColumn> -->
                      <VxeColumn field="Quantity" title="數量" resizable :edit-render="{}">
                        <template #edit="{ row }">
                          <vxe-input type="number" v-model="row.Quantity" />
                        </template>
                      </VxeColumn>
                      <VxeColumn field="Name" title="名稱" resizable :edit-render="{}">
                        <template #edit="{ row }">
                          <vxe-input v-model="row.Name" />
                        </template>
                      </VxeColumn>
                      <!-- <VxeColumn title="操作" width="160" resizable
                        v-if="grid?.editingRow?.Status !== $enums.OrderStatus.Canceled.Value">
                        <template #header>
                          <vxe-button @click="itemsGridOptions.insert">新增
                          </vxe-button>
                        </template>

                        <template #default="{ row }">
                          <template v-if="itemsGrid.isActiveByRow(row)">
                            <vxe-button @click="itemsGridOptions.save(row)">確認</vxe-button>
                            <vxe-button @click="itemsGridOptions.cancel(row)">取消</vxe-button>
                          </template>

                          <template v-else>
                            <vxe-button @click="itemsGridOptions.edit(row)">編輯</vxe-button>
                            <vxe-button @click="itemsGridOptions.delete(row)">刪除</vxe-button>
                          </template>
                        </template>
                      </VxeColumn> -->
                    </VxeTable>
                  </div>
                </div>
              </template>

              <template #column-remark="{ data }">
                <vxe-textarea placeholder="請輸入備註" v-model="data.Remark" />
              </template>

              <template #column-shipping="{ data }" v-if="row.Shipping">
                <div class="intro-y overflow-hidden box border p-3">
                  <div class="flex items-center ml-1 mb-3">
                    <a href="#" @click.prevent="toggleVisible.sender = !toggleVisible.sender">寄件人資訊</a>
                    <a href="#" @click.prevent="toggleVisible.sender = !toggleVisible.sender">
                      <FontAwesome class="ml-2 h-4" :icon="toggleVisible.sender ? 'angle-up' : 'angle-down'" />
                    </a>
                  </div>
                  <div class="ml-1" v-show="toggleVisible.sender">
                    <VxeForm>
                      <VxeFormItem title="姓名" field="Shipping.SenderName" span="8">
                        <template #default>
                          <VxeInput v-model="data.Shipping.SenderName" />
                        </template>
                      </VxeFormItem>
                      <VxeFormItem title="Email" field="Shipping.SenderEmail" span="8">

                        <template #default>
                          <VxeInput v-model="data.Shipping.SenderEmail" />
                        </template>
                      </VxeFormItem>
                      <VxeFormItem title="手機" field="Shipping.SenderMobilePhone" span="8">

                        <template #default>
                          <VxeInput v-model="data.Shipping.SenderMobilePhone" />
                        </template>
                      </VxeFormItem>
                    </VxeForm>
                  </div>
                </div>
                <div class="intro-y overflow-hidden box border p-3 mt-5">
                  <div class="flex items-center ml-1 mb-3">
                    <a href="#" @click.prevent="toggleVisible.receiver = !toggleVisible.receiver">收件人資訊</a>
                    <a href="#" @click.prevent="toggleVisible.receiver = !toggleVisible.receiver">
                      <FontAwesome class="ml-2 h-4" :icon="toggleVisible.receiver ? 'angle-up' : 'angle-down'" />
                    </a>
                  </div>
                  <div class="ml-1" v-show="toggleVisible.receiver">
                    <VxeForm>
                      <VxeFormItem title="姓名" field="Shipping.ReceiverName" span="8">

                        <template #default>
                          <VxeInput v-model="data.Shipping.ReceiverName" />
                        </template>
                      </VxeFormItem>
                      <VxeFormItem title="Email" field="Shipping.ReceiverEmail" span="8">

                        <template #default>
                          <VxeInput v-model="data.Shipping.ReceiverEmail" />
                        </template>
                      </VxeFormItem>
                      <VxeFormItem title="手機" field="Shipping.ReceiverMobilePhone" span="8">

                        <template #default>
                          <VxeInput v-model="data.Shipping.ReceiverMobilePhone" />
                        </template>
                      </VxeFormItem>
                      <VxeFormItem title="收件地址" span="24">

                        <template #default>
                          <AddressSelectBox v-model="data.Shipping.ReceiverAddress" />
                        </template>
                      </VxeFormItem>
                    </VxeForm>
                  </div>
                </div>
                <div class="intro-y overflow-hidden box border p-3 mt-5">
                  <div class="flex items-center ml-1 mb-3">
                    <a href="#" @click.prevent="toggleVisible.invoice = !toggleVisible.invoice">發票資訊</a>
                    <a href="#" @click.prevent="toggleVisible.invoice = !toggleVisible.invoice">
                      <FontAwesome class="ml-2 h-4" :icon="toggleVisible.invoice ? 'angle-up' : 'angle-down'" />
                    </a>
                  </div>
                  <div class="ml-1" v-show="toggleVisible.invoice">
                    <vxe-form>
                      <vxe-form-item title="發票類型" span="24">

                        <template #default>
                          <vxe-select v-model="data.Shipping.InvoiceType">
                            <vxe-option v-for="item in Object.values($enums.InvoiceType)" :label="item.Name"
                              :value="item.Value" :key="'invoice-type-' + item.Value"></vxe-option>
                          </vxe-select>
                        </template>
                      </vxe-form-item>

                      <template v-if="data.Shipping.InvoiceType === $enums.InvoiceType.ThreeCopies.Value">
                        <vxe-form-item title="發票抬頭" span="12">
                          <template #default>
                            <vxe-input v-model="data.Shipping.InvoiceTitle" />
                          </template>
                        </vxe-form-item>
                        <vxe-form-item title="統一編號" span="12">

                          <template #default>
                            <vxe-input v-model="data.Shipping.TaxId" />
                          </template>
                        </vxe-form-item>
                      </template>
                      <vxe-form-item v-if="data.Shipping.InvoiceType === $enums.InvoiceType.TwoCopies.Value"
                        title="收件人姓名" span="24">

                        <template #default>
                          <vxe-input v-model="data.Shipping.InvoiceReceiverName" />
                        </template>
                      </vxe-form-item>
                      <vxe-form-item title="收件人地址" span="24">

                        <template #default>
                          <AddressSelectBox v-model="data.Shipping.InvoiceAddress" />
                        </template>
                      </vxe-form-item>
                    </vxe-form>
                  </div>
                </div>
              </template>
              <!-- <template #column-payments="{ data }">
                <div class="intro-y overflow-hidden box border p-1">
                  <div class="flex items-center ml-1">
                    <a href="#" @click.prevent="toggleVisible.payments = !toggleVisible.payments">繳費明細</a>
                    <a href="#" @click.prevent="toggleVisible.payments = !toggleVisible.payments">
                      <FontAwesome class="ml-2 h-4" :icon="toggleVisible.payments ? 'angle-up' : 'angle-down'" />
                    </a>
                  </div>
                  <div v-show="toggleVisible.payments">
                    <VxeTable ref="paymentsGrid" :data="data?.Payments" v-bind="paymentsGridOptions.config">
                      <VxeColumn field="Time" title="繳費時間" resizable :edit-render="{}">
                        <template #edit="{ row }">
                          <vxe-input type="datetime" v-model="row.Time" />
                        </template>
                      </VxeColumn>
                      <VxeColumn field="Type" title="繳費方式" resizable :edit-render="{}">
                        <template #edit="{ row }">
                          <vxe-select v-model="row.Type">
                            <vxe-option v-for="(item, index) in Object.values($enums.PaymentType)"
                              :key="'payment-type-' + index" :label="item.Name" :value="item.Value"></vxe-option>
                          </vxe-select>
                        </template>
                      </VxeColumn>
                      <VxeColumn field="Pan" title="卡/帳號" resizable :edit-render="{}">
                        <template #edit="{ row }">
                          <vxe-input v-model="row.Pan" />
                        </template>
                      </VxeColumn>
                      <VxeColumn field="Amount" title="金額" resizable :edit-render="{}">
                        <template #edit="{ row }">
                          <vxe-input type="number" v-model="row.Amount" />
                        </template>
                      </VxeColumn>
                      <VxeColumn field="Status" title="繳費狀態" resizable :edit-render="{}">
                        <template #edit="{ row }">
                          <vxe-select v-model="row.Status">
                            <vxe-option v-for="(item, index) in Object.values($enums.PaymentStatus)"
                              :key="'payment-status-' + index" :label="item.Name" :value="item.Value"></vxe-option>
                          </vxe-select>
                        </template>
                      </VxeColumn>
                      <VxeColumn title="操作" width="160" resizable>
                        <template #header>
                          <vxe-button @click="itemsGridOptions.insert">新增
                          </vxe-button>
                        </template>
                        <template #default="{ row }">
                          <template v-if="itemsGrid.isActiveByRow(row)">
                            <vxe-button @click="itemsGridOptions.save(row)">確認</vxe-button>
                            <vxe-button @click="itemsGridOptions.cancel(row)">取消</vxe-button>
                          </template>
                          <template v-else>
                            <vxe-button @click="itemsGridOptions.edit(row)">編輯</vxe-button>
                            <vxe-button @click="itemsGridOptions.delete(row)">刪除</vxe-button>
                          </template>
                        </template>
                      </VxeColumn>
                    </VxeTable>
                  </div>
                </div>
              </template> -->
            </vxe-form>
          </template>

          <template #modal-footer>
            <div v-if="grid?.editingRow?.Status !== $enums.OrderStatus.Canceled.Value">
              <vxe-button type="submit" status="primary" content="確認"
                @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })">
              </vxe-button>
              <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button>
            </div>
          </template>

          <template #filter-name="{ column, $panel }">
            <div v-for="(option, index) in column.filters" :key="index" class="flex item-center justify-center">
              <input type="checkbox" class="input input--switch border mt-4 ml-2" :checked="option.checked"
                @input="grid.changeFilterEvent(event, option, $panel, true)" />
              <div class="relative m-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600">
                  {{ option.label }}
                </div>
                <input v-model.trim="option.data" :disabled="!option.checked" type="text"
                  class="input pl-12 w-full border col-span-4" placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(event, option, $panel)" />
              </div>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive } from "vue";
import CloudFun, { Condition, Operator } from "@cloudfun/core";
import axios from "axios";
import { commafy } from "xe-utils";
import { helper as $h } from "@/utils/helper";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps, VxeTableProps } from "vxe-table";
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import AddressSelectBox from "@/components/address-select-box/Main.vue";
import DateRange from "@/components/date-select/Range.vue";

interface QueryParams {
  source: number | null;
  status: number | null;
  startDate: any; // Date | null;
  endDate: any; // Date | null;
  productId: number;
  Id: number;
}

function queryToCondition(condition: Condition, query: QueryParams): Condition {
  condition.and("DataMode", Operator.NotEqual, 2);
  if (query.startDate) {
    condition.and(
      "Time",
      Operator.GreaterThanOrEqual,
      query.startDate
    );
  }
  if (query.endDate) {
    condition.and("Time", Operator.LessThan, query.endDate);
  }
  if (query.source != null) {
    condition.and("OrderSource", Operator.Equal, query.source);
  }
  if (query.status != null) {
    condition.and("Status", Operator.Equal, query.status);
  }
  if (query.productId) {
    condition.and("Items.ProductId", Operator.Equal, query.productId);
  }
  if (query.Id) {
    condition.and("Id", Operator.Equal, query.Id);
  }
  return condition;
}

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    AddressSelectBox,
    DateRange
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const toggleVisible = reactive({
      items: true,
      sender: true,
      receiver: true,
      invoice: true,
      payments: true
    })
    const isExporting = ref(false);
    const gridQueryParams = reactive<QueryParams>({
      source: null,
      status: null,
      startDate: new Date(),
      endDate: new Date(),
      productId: 0,
      Id: 0
    });
    gridQueryParams.endDate.setHours(23, 59, 59, 0)
    gridQueryParams.startDate!.setMonth(gridQueryParams.startDate!.getMonth() - 1);

    const gridOptions: GridOptions = {
      title: "訂單",
      canCreate: true,
      canUpdate: true,
      canDelete: false,
      stripe: false,
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      columns: [
        { field: 'Time', width: "150", title: '時間', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => $h.formatDate(cellValue, 'YYYY/MM/DD HH:mm:ss') },
        { field: "Number", title: "編號", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Member.Person.Name", title: "會員姓名", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Member.Person.MobilePhone", title: "會員電話", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Status", width: "75", title: "狀態", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.OrderStatus).find(e => e.Value === cellValue)?.Name : undefined },
        { field: "Type", width: "80", title: "付款方式", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.PaymentType).find(e => e.Value === cellValue)?.Name : undefined },
        { field: "OrderSource", width: "75", title: "類型", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.OrderSource).find(e => e.Value === cellValue)?.Name : undefined },
        { field: "SaleAmount", title: "售價總額", showHeaderOverflow: true, showOverflow: true, align: "right", formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue) },
        { field: "DiscountAmount", title: "折扣總額", showHeaderOverflow: true, showOverflow: true, align: "right", formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue) },
        { field: "PointsAmount", title: "紅利點數", showHeaderOverflow: true, showOverflow: true, align: "right", sortable: true },
        { field: "Amount", title: "訂單金額", showHeaderOverflow: true, showOverflow: true, align: "right", formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue) },
        { field: "Cash", title: "現金金額", showHeaderOverflow: true, showOverflow: true, align: "right", formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue) },
        { field: "CreditCard", title: "刷卡金額", showHeaderOverflow: true, showOverflow: true, align: "right", formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue) },
        { field: "ATM", title: "匯款金額", showHeaderOverflow: true, showOverflow: true, align: "right", formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue) },
      ],
      rowClassName(params) {
        const enums = model?.enums as any;
        return params.row.Status === enums.OrderStatus.PaymentConfirmed.Value
          ? "bg-indigo-100"
          : params.row.Status === enums.OrderStatus.Finished.Value
            ? "bg-gray-300"
            : ""
      },
      promises: {
        query: model ? (params) => {
          if (!params.sortings) params.sortings = [];
          params.sortings.push({ column: "Time", order: 1 });
          params.condition = queryToCondition(params.condition!, gridQueryParams);
          params.condition.and("Payment", Operator.Equal, "");
          return model.dispatch("order/query", params);
        } : undefined,
        queryAll: model ? () => model.dispatch("order/query") : undefined,
        save: model ? (params) => model!.dispatch("order/save", params) : undefined,
      },
      modalConfig: { width: "100%", height: "100%", showFooter: true },
    };

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: "right",
      span: 3,
      items: [
        { field: "Time", title: "下單時間", span: 12, itemRender: { name: "$input", props: { type: "datetime", disabled: true } } },
        { field: "Number", title: "編號", span: 12, itemRender: { name: "$input", props: { placeholder: "未輸入則系統自動產生" } } },
        { field: "MemberId", title: "會員", span: 12, slots: { default: "column-member-id" } },
        // { field: "Status", title: "狀態", span: 12, itemRender: { name: "$select", options: model ? Object.values(model.enums.OrderStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { title: "狀態", span: 12, slots: { default: "column-status" } },
        { field: "ShippingAmount", title: "運費金額", span: 12, itemRender: { name: "$input", props: { type: "number" } } },
        { field: "AdjustAmount", title: "現金折抵", span: 12, itemRender: { name: "$input", props: { type: "number" } } },
        { field: "OrderSource", title: "類型", span: 12, itemRender: { name: "$select", options: model ? Object.values(model.enums.OrderSource).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        // { field: "SaleAmount", title: "原價總額", span: 12, itemRender: { name: "$input", props: { type: "number" } } },
        // { field: "DiscountAmount", title: "折扣總額", span: 12, itemRender: { name: "$input", props: { type: "number" } } },
        { field: "Amount", title: "訂單金額", span: 12, itemRender: { name: "$input", props: { type: "number" } } },
        { span: 24, slots: { default: "column-items" } },
        { title: "備註", span: 24, slots: { default: "column-remark" } },
        { span: 24, slots: { default: "column-shipping" } },
        // { span: 24, slots: { default: "column-payments" } }
        // { title: "發票資訊", span: 24, titleWidth: "100", titleAlign: "left" },
        // { field: "InvoiceAddress", span: 24, slots: { default: "column-invoice" } }
      ],
      rules: {
        // MemberId: [{ required: true }],
        // SaleAmount: [{ required: true }],
        // Amount: [{ required: true }],
      }
    };

    const memberSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: 'value',
      placeholder: '選擇會員',
      textField: 'label',
      valueField: 'value',
      columns: [
        { field: 'label', title: '姓名', showHeaderOverflow: true, showOverflow: true, sortable: true }
      ],
      promises: {
        find: (value) => new Promise(resolve => {
          model!.dispatch("member/find", value).then(res => resolve({ label: res.Person.Name, value: res.Id }));
        }),
        query: (params) => model!.dispatch("member/options", params) // eslint-disable-line
      },
    }

    const productSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: 'Id',
      placeholder: '選擇商品',
      textField: 'Name',
      valueField: 'Id',
      transfer: true,
      columns: [
        { field: 'Brand.Name', title: '品牌', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Number', title: '編號', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      formatText(row) {
        return Promise.resolve(row ? `(${row.Brand?.Name}) ${row.Number} ${row.Name}` : "");
      },
      promises: {
        find: (value) => model!.dispatch("product/find", value),
        query: (params) => model!.dispatch("product/query", params) // eslint-disable-line
      },
    }

    const itemsGrid = ref<any>({});
    const itemsGridOptions = {
      config: {
        autoResize: true,
        keepSource: true,
        showFooter: true,
        editConfig: { trigger: 'manual', mode: 'row', autoClear: false },
        editRules: {
          ProductId: [{
            // required: true,
            message: '必要欄位',
            validator: (params) => {
              if (params.row.SalePrice > 0 && !params.row.ProductId) {
                return new Error('必要欄位');
              }
            },
          }],
          Brand: [
            {
              required: true,
              validator: (params) => {
                if (typeof params.row.BrandName !== 'string') {
                  return new Error('格式錯誤');
                }
              },
            }
          ],
          Number: [
            {
              required: true,
              validator: (params) => {
                if (typeof params.row.ProductNumber !== 'string') {
                  return new Error('格式錯誤');
                }
              },
            }
          ],
          Barcode: [
            {
              required: true,
              validator: (params) => {
                if (typeof params.row.ProductBarcode !== 'string') {
                  return new Error('格式錯誤');
                }
              },
            }
          ],
          SalePrice: [
            { required: true, type: 'number' }
          ],
          // Discount: [
          //   { type: 'number', min: 0 }
          // ],
          Price: [
            { required: true, type: 'number' } // , min: 1, message: '金額須為1以上'
          ],
          Quantity: [
            { required: true, type: 'number', min: 1, message: '數量須為1以上' }
          ],
          Pices: [
            {
              required: false,
              validator: (params) => {
                if (typeof params.row.Pices !== 'string') {
                  return new Error('格式錯誤');
                }
              },
            }
          ],
        },
        footerMethod({ columns, data }) {
          return [
            columns.map((column, columnIndex) => {
              if (columnIndex === 3) {
                return "原價總額";
              }
              if (columnIndex === 4) {
                return commafy(data.reduce((pre, cur) => pre + (cur.Price * cur.Quantity), 0));
              }
              if (columnIndex === 5) {
                return "折扣總額\n紅利點數";
              }
              if (columnIndex === 6) {
                return -discountAmount.value + "\n" + -pointsAmount.value
              }
              if (columnIndex === 7) {
                return "訂單金額";
              }
              if (columnIndex === 8) {
                return commafy((orderAmount.value ?? 0));
                // return "123456";
              }
            })
          ]
        }
      } as VxeTableProps,
      async insert() {
        const { row: newRow } = await itemsGrid.value.insert({
          SalePrice: 1,
          Price: 1,
          Discount: 0,
          Quantity: 1
        });
        itemsGrid.value.setActiveRow(newRow);
      },
      edit(row: any) {
        itemsGrid.value.setActiveRow(row);
      },
      delete(row: any) {
        itemsGrid.value.remove(row);
      },
      async cancel(row: any) {
        if (row.Id) {
          itemsGrid.value.revertData(row)
          itemsGrid.value.clearActived();
          return;
        }

        if (!row.ProductId || !row.SalePrice || !row.Price || !row.Quantity) {
          itemsGridOptions.delete(row);
          return;
        }

        itemsGrid.value.clearActived();
      },
      async save(row: any) {
        const error = await itemsGrid.value.validate(row);
        if (!error) {
          itemsGrid.value.clearActived();
        }
      }
    }

    const paymentsGrid = ref<any>({});
    const paymentsGridOptions = {
      config: {
        autoResize: true,
        keepSource: true,
        showFooter: true,
        editConfig: { trigger: 'manual', mode: 'row', autoClear: false },
        editRules: {
          Time: [{ required: true, message: '必要欄位' }],
          Type: [{ required: true, message: '必要欄位' }],
          Pan: [{ required: true, message: '必要欄位' }],
          Amount: [{ required: true, message: '必要欄位' }],
          Status: [{ required: true, message: '必要欄位' }]
        }
      } as VxeTableProps,
      async insert() {
        const { row: newRow } = await paymentsGrid.value.insert({
          Time: new Date(),
          Type: 1,
          Amount: 1,
          Status: 10
        });
        paymentsGrid.value.setActiveRow(newRow);
      },
      edit(row: any) {
        paymentsGrid.value.setActiveRow(row);
      },
      delete(row: any) {
        paymentsGrid.value.remove(row);
      },
      async cancel(row: any) {
        if (row.Id) {
          paymentsGrid.value.revertData(row)
          paymentsGrid.value.clearActived();
          return;
        }

        if (!row.Time || !row.Type || !row.Pan || !row.Amount || !row.Status) {
          paymentsGridOptions.delete(row);
          return;
        }

        paymentsGrid.value.clearActived();
      },
      async save(row: any) {
        const error = await paymentsGrid.value.validate(row);
        if (!error) {
          paymentsGrid.value.clearActived();
        }
      }
    }

    // 0718 補上調整金額及運費金額
    const adjustAmount = ref<number>(0);
    const shippingAmount = ref<number>(0);
    const orderAmount = ref<number>(0);
    const pointsAmount = ref<number>(0);
    const discountAmount = ref<number>(0);

    return {
      isExporting,
      grid,
      gridQueryParams,
      gridOptions,
      formOptions,
      memberSelectOptions,
      productSelectOptions,
      toggleVisible,
      itemsGrid,
      itemsGridOptions,
      paymentsGrid,
      paymentsGridOptions,
      adjustAmount,
      shippingAmount,
      orderAmount,
      pointsAmount,
      discountAmount,
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    async onGridEdit(row: any, callback: any) {
      if (!row.Id) {
        row.Status = 0;
        row.OrderSource = 0;
        row.SaleAmount = 0;
        row.AdjustAmount = 0;
        row.ShippingAmount = 0;
        row.DiscountAmount = 0;
        row.Amount = 0;
        row.Shipping = {
          ReceiverAddress: {}
        };
      } else {
        const entity = await this.$model.dispatch("order/find", row.Id);
        Object.assign(row, entity);
        this.adjustAmount = row.AdjustAmount;
        this.shippingAmount = row.ShippingAmount;
        this.orderAmount = row.Amount;
        this.pointsAmount = row.PointsAmount;
        this.discountAmount = row.DiscountAmount;
        if (row.Items?.length > 0) {
          const itemIds = row.Items.filter((e: any) => e.ProductId).map((e: any) => e.ProductId);
          const items = await this.$model.dispatch("product/query", { condition: new Condition("Id", Operator.In, itemIds) });
          row.Items.filter((e: any) => e.ProductId).forEach((e: any) => {
            const item = items.find((i: any) => i.Id === e.ProductId);
            e.ProductName = `(${item.Brand.Name}) ${item.Number} ${item.Name}`;
            e.BrandName = `${item.Brand.Name}`
            e.ProductNumber = `${item.Number}`
            e.ProductBarcode = `${item.Barcode}`
            e.ProductPhotoUri = `${item.Photo.Uri}`
          });
        }
        row.Payments = await this.$model.dispatch("payment/query", { condition: new Condition("OrderId", Operator.Equal, row.Id) });

        row.Shipping.ReceiverAddress = row.Shipping.ReceiverAddress || {};
        row.Shipping.InvoiceAddress = row.Shipping.InvoiceAddress || {};
        // 只保留數字
        row.Shipping.ReceiverMobilePhone = row.Shipping.ReceiverMobilePhone === null ? null : row.Shipping.ReceiverMobilePhone.replace(/\D/g, '');
        row.Shipping.SenderMobilePhone = row.Shipping.SenderMobilePhone === null ? null : row.Shipping.SenderMobilePhone.replace(/\D/g, '');
      }
      callback();
    },
    async onGridRemove(row: any) {
      const comfirmedCardPayment = (await this.$model.dispatch("payment/query", { page: 1, pageSize: 1, condition: new Condition("DataMode", Operator.NotEqual, 2).and("OrderId", Operator.Equal, row.Id).and("Type", Operator.Equal, 1).and("Status", Operator.Equal, 24) }))?.data[0];
      const msg = comfirmedCardPayment ? "此訂單已刷卡付款，請記得去綠界進行退刷，確定要取消此筆資料嗎?" : "確定要取消此筆資料嗎?";
      if (this.gridOptions.promises?.save && confirm(msg)) {
        this.grid.gridOptions.loading = true;
        row.Status = 30;
        this.gridOptions.promises.save({ updateRows: [row] }).then(
          () => {
            this.grid.instance.commitProxy("query").finally(() => {
              this.grid.gridOptions.loading = false;
            });
          },
          (failure: any) => {
            if (Array.isArray(failure)) {
              failure.forEach((e: any) =>
                CloudFun.send("error", {
                  subject: "取消失敗",
                  content: e.message,
                })
              );
            } else {
              CloudFun.send("error", {
                subject: "取消失敗",
                content: failure.message || failure,
              });
            }
            this.grid.gridOptions.loading = false;
          }
        );
      }
    },
    onFormSubmit(row: any, callback: any) {
      row.Items = this.itemsGrid.getTableData().fullData;
      // row.Payments = this.paymentsGrid.getTableData().fullData;
      callback();
    },
    async onProductSelectChange(id: number, row: any) {
      const product = await this.$model.dispatch("product/find", id)
      row.ProductName = product.Name;
      row.Price = product.PurchasePrice;
      row.Discount = product.PurchaseDiscount || 0;
      row.SalePrice = product.SalePice;
    },
    async onExport(type: number, orderId: number) {
      this.hideBatchDropDown();
      let requestUrl = "";
      let filename = "";
      const params = { ...this.gridQueryParams };
      switch (type) {
        case 0:
          requestUrl = "/api/Reports/Order";
          filename = "訂單總表.xlsx";
          break;
        case 1:
          requestUrl = "/api/Reports/Order/Details";
          filename = "訂單明細表.xlsx";
          break;
        case 2:
          params.startDate = null;
          params.endDate = null;
          requestUrl = "/api/Reports/Order/Daily";
          filename = "訂單日報表.xlsx";
          break;
        case 3:
          params.Id = orderId;
          requestUrl = "/api/Reports/Order/Details";
          filename = "訂單資料.xlsx";
          break;
        default:
          return;
      }
      console.log(params);
      requestUrl = process.env.VUE_APP_BACKEND_URL + requestUrl;
      this.isExporting = true;
      // 使用此種方式會有Uncaught (in promise) undefined的問題
      // model!.dispatch("order/export", { orderId: grid.value.editingRow.Id })
      axios.post(requestUrl,
        { condition: queryToCondition(new Condition(), params), sortings: [{ column: "Time", order: 1 }] },
        { responseType: "blob", headers: { Authorization: 'Basic ' + btoa(`${this.$user?.Id}:${this.$user?.Token}`) } })
        .then((response: any) => {
          const a = document.createElement("a");
          const url = window.URL.createObjectURL(response.data);
          a.href = url;
          a.target = "_blank";
          a.download = filename;
          a.click();
        }).finally(() => {
          this.isExporting = false;
        });
    }
  }
});
</script>
